<template>
  <absolute-loader :showLoader="true" />
</template>
<script>
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'
import { toast } from '@/modules/notifications'

export default {
  name: 'Authorize',
  asyncData ({ store, route, redirect }) {
    if (!route.query.buyerToken) {
      redirect({ name: 'login', params: { lang: store.state.ui.codeLang } })
    }
  },
  methods: {
    init () {
      let buyerToken = this.$route.query.buyerToken
      return this.$store.dispatch('pl_amazon/authorize', { buyerToken })
        .then((isLogged) => {
          if (isLogged) {
            this.$router.push({ name: 'myProfile', params: { lang: this.$store.state.ui.codeLang } })
          } else {
            throw new Error('Cannot log user')
          }
        })
        .catch((error) => {
          toast.error(error)
          this.$router.push({ name: 'login', params: { lang: this.$store.state.ui.codeLang } })
        })
    }
  },
  mounted () {
    if (process.client) {
      this.init()
    }
  },
  components: {
    AbsoluteLoader
  }
}
</script>
